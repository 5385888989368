import { Paper, Stack } from "@mui/material"

type Props = {
    message: string;
}

export default function CustomPaper({ message }: Props) {
    return (
        <Stack
            sx={{
                width: "100%",
            }}
        >
            <Paper 
                sx={{ m: 2 }}
            >
                <h2 style={{ textAlign: "center" }}>
                    {message}
                </h2>
            </Paper>
        </Stack>
    )
}
import { useState, useRef } from "react";
import Webcam from 'react-webcam';
import ModalSuuccessRead from "./ModalSuuccessRead";
import uploadImage from "../../service/uploadImage";
import faceRecognition from "../../service/faceRecognition";
import { Button, Stack } from "@mui/material";
import CustomPaper from "../../ui-kit/CustomPaper";

type Props = {
    imageNameFromQR: string; 
}

export default function FaceRecognition({ imageNameFromQR }: Props) {
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const webcamRef = useRef(null);

    const capture = async () => {
        setIsLoading(true);
        setIsOpen(true);
        if (webcamRef.current) {
            // @ts-ignore
            const imageSrc = webcamRef.current.getScreenshot();
            const imageName = await uploadImage(imageSrc);
            const result = await faceRecognition(imageName, imageNameFromQR);
            setIsSuccess(result);
        }
        setIsLoading(false);
    };

    const videoConstraints = {
        facingMode: { ideal: "environment" } // Use "environment" for the rear camera
    };

    return (
        <Stack 
            width="100vw"
            height="100vh"
            alignItems="center"
        >
            <ModalSuuccessRead 
                open={isOpen} 
                isSuccess={isSuccess} 
                isLoading={isLoading}
            />
            <CustomPaper 
                message="Por favor, Realize o reconhecimento facial do colaborador"
            />
            <Webcam 
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
                style={{
                    borderRadius: "15px",
                    boxShadow: "6px 8px 14px 6px rgba(0,0,0,0.35)",
                    height: "92vw",
                    width: "92vw",
                    marginBottom: "30px"
                }}
            />
            {
                isLoading ?
                <CustomPaper message="Carregando..."/> :
                isSuccess ?
                <CustomPaper message="Colaborador reconhecido com sucesso"/> :
                <>
                    <Button
                        onClick={capture}
                        variant="contained"
                    >
                        Reconhecer Colaborador
                    </Button>
                    <CustomPaper message="Colaborador ainda não reconhecido" />
                </>
            }
        </Stack>
    );
}

import React from "react";
import { Stack } from "@mui/material";
import CustomTypography from "../../../ui-kit/CustomTypography";

type Props = {
    field: string;
    value: string;
}

export default function RowUserDetails({ field, value }: Props) {
    return (
        <Stack direction="row" width="100%">
            <CustomTypography isBold >
                {field}
            </CustomTypography> 
            <CustomTypography style={{ paddingRight: "6px" }}>:</CustomTypography>
            <CustomTypography >
                {value}
            </CustomTypography>
        </Stack>
    );
}
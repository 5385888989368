import { useEffect, useState } from "react"
import QRCodeRecognition from "./QRCodeRecognition";
import FaceRecognition from "./FaceRecognition";
import { QrCodeContent } from "../interfaces/QrCodeContent.interface";
import NothingContainedPaper from "./NothingContainedPaper";

export default function FaceRecognitionComponents() {
    const [isFaceRecognitionStep, setIsFaceRecognitionStep] = useState<boolean>(false)
    const [ isNothingContainedShowing, setIsNothingContainedShoing ] = useState<boolean>(false)
    const [qrCodeContent, setQrCodeContent] = useState<QrCodeContent>()

    useEffect(() => {
        if (isNothingContainedShowing && qrCodeContent) {
            const userHasInternet: boolean = navigator.onLine;
            setIsFaceRecognitionStep(userHasInternet);
        }
    }, [isNothingContainedShowing, qrCodeContent]);

    if (isFaceRecognitionStep
        && qrCodeContent?.namePicture
    ) return <FaceRecognition imageNameFromQR={qrCodeContent?.namePicture} />
    if (isNothingContainedShowing && 
        qrCodeContent
    ) return <NothingContainedPaper qrCodeContent={qrCodeContent} /> 
    return <QRCodeRecognition 
        setIsNothingContainedShoing={setIsNothingContainedShoing}
        setQrCodeContent={setQrCodeContent}
    />
}
import AWS from 'aws-sdk';

/**
 * Uploads uma imagem para um S3 bucket.
 * @param imageName - Nome da imagem do colaborador na portaria.
 * @param imageNameFromQR - Nome da imagem do colaborador na vindo do QR code.
 * @returns A url da imagem upada
 */
export default async function faceRecognition(imageName: string, imageNameFromQR: string): Promise<boolean> {
    console.log("🚀 ~ faceRecognition ~ imageName:", imageName)
    console.log("🚀 ~ faceRecognition ~ imageNameFromQR:", imageNameFromQR)
    const rekognition = new AWS.Rekognition({
      accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID_AWS,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY_AWS,
      region: process.env.REACT_APP_REGION_AWS
    });
    console.log("🚀 ~ faceRecognition ~ rekognition:", rekognition)

    const params = {
      SourceImage: {
        S3Object: {
          Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
          Name: imageNameFromQR + ".dat"
        },
      },
      TargetImage: {
        S3Object: {
          Bucket: "oceanpact-rekognition",
          Name: imageName
        },
      },
      SimilarityThreshold: 70
    }
    console.log("🚀 ~ faceRecognition ~ params:", params)

    try {
        const response = await rekognition.compareFaces(params).promise();
        console.log("🚀 ~ faceRecognition ~ response:", response)
        if (response?.FaceMatches?.length) {
            const data = response.FaceMatches[0];
            const similarity = data.Similarity;
            if (similarity && similarity > 90) {
                return true
            } else {
                return false
            }
        }
        return false;
    } catch (error) {
        console.error('Error uploading image to S3:', error);
        return false
    }
}
import { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import QRCodeReader from "./QRCodeReader";
import ModalSuuccessRead from "./ModalSuuccessRead";
import { QrCodeContent } from "../../interfaces/QrCodeContent.interface";
import CustomPaper from "../../ui-kit/CustomPaper";

type Props = {
    setIsNothingContainedShoing: (value: boolean) => void;
    setQrCodeContent: (value: QrCodeContent) => void;
}

export default function QRCodeRecognition({ 
    setIsNothingContainedShoing,
    setQrCodeContent
}: Props) {
    const [ open, setOpen ] = useState<boolean>(false)
    const [ isLoading, setIsLoading ] = useState<boolean>(false)
    const [ isSuccess, setIsSuccess ] = useState<boolean>(false)

    return (
        <Stack 
            justifyContent="center"
            alignItems="center" 
            height="86vh" 
            width="100%"
        >
            <ModalSuuccessRead open={open} isSuccess={isSuccess} isLoading={isLoading} />
            <CustomPaper 
                message={`Escaneie o QR Code do comprovante de "Nada Consta" do colaborador`}
            />
            <QRCodeReader 
                setIsNothingContainedShoing={setIsNothingContainedShoing}
                setQrCodeContent={setQrCodeContent}
                setIsSuccess={setIsSuccess}
                setIsLoading={setIsLoading}
                setOpen={setOpen} 
            />
            {/* <FooterMessage /> */}
        </Stack>
    )
}
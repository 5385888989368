import React from "react";
import { Stack } from "@mui/material";
import CustomTypography from "../../ui-kit/CustomTypography";

export default function FooterMessage({ emissionDate }: { emissionDate: string }) {
    return (
        <Stack p={1} alignItems="center">
            <CustomTypography isBold center>
                Esse Nada Consta foi emitido no dia: {emissionDate}
            </CustomTypography>
        </Stack>
    ); 
    
}
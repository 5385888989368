import React from "react";
import { TableCell, TableHead, TableRow } from "@mui/material";
import CustomTypography from "../../../ui-kit/CustomTypography";

export default function TableHeader() {
    return (
        <TableHead>
            <TableRow>
                <TableCell>
                    <CustomTypography isBold>
                        Nome do documento
                    </CustomTypography>
                </TableCell>
                <TableCell align="right">
                    <CustomTypography isBold>
                        Data de Expiração
                    </CustomTypography>
                </TableCell>
            </TableRow>
        </TableHead>
    );
}
import { RowDocumentNothinContains } from "../interfaces/RowDocumentNothinContains";

export default function verificaValidadeDocumental(documetsEmployee: RowDocumentNothinContains[]) {
    let allOk = true;

    documetsEmployee.forEach(documentData => {
        if (new Date(documentData.expiration) < new Date()) allOk = false;
    })

    return allOk;
}
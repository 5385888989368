import React from 'react';
import Box from '@mui/material/Box';
import { CircularProgress, Stack } from '@mui/material';
import Content from './Content';

export default function ContentLoading() {
    return (
        <Content>
            <h4 style={{ textAlign: "center" }}>
                Aguarde enquanto verificação a autenticidade do seu Nada Consta
            </h4>
            <Box p={3}>
                <CircularProgress size='70px' />
            </Box>
        </Content>
    )
}
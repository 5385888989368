import { Scanner } from '@yudiel/react-qr-scanner';
import { QrCodeContent } from '../../interfaces/QrCodeContent.interface';
import verificaValidadeDocumental from '../../utils/verificaValidadeDocumental';

type Props = {
    setIsNothingContainedShoing: (value: boolean) => void;
    setQrCodeContent: (value: QrCodeContent) => void;
    setIsSuccess: (value: boolean) => void;
    setIsLoading: (value: boolean) => void;
    setOpen: (value: boolean) => void; 
}

export default function QRCodeReader({ 
    setIsNothingContainedShoing,
    setQrCodeContent,
    setIsSuccess,
    setIsLoading,
    setOpen, 
}: Props) {
    return (
        <Scanner 
            onScan={
                async (result) => {
                    setOpen(true)

                    try {
                        const qrCodeContentString = result[0].rawValue;
                    
                        // Remove caracteres de controle não imprimíveis
                        const sanitizedQrCodeContentString = qrCodeContentString.replace(/[\u0000-\u001F\u007F-\u009F]/g, '');
                        sessionStorage.setItem('qrCodeContent', sanitizedQrCodeContentString);
                    
                        const qrCodeContentObj = JSON.parse(sanitizedQrCodeContentString) as QrCodeContent;
                    
                        const docsOk = verificaValidadeDocumental(qrCodeContentObj.documetsEmployee);
                        if (docsOk) {
                            setQrCodeContent(qrCodeContentObj);
                            setIsNothingContainedShoing(true);
                            setOpen(false);
                        } else {
                            setIsSuccess(false);
                        }
                    } catch (error) {
                        setIsSuccess(false);
                    } finally {
                        setIsLoading(false);
                    }
                }
            } 
            styles={{
                container: {
                    height: "100vw",
                    width: "100vw",
                }
            }}
        />
    )
}
import React, { ReactNode } from "react";
import Stack from "@mui/material/Stack/Stack";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

export default function Content({ children }: { children: ReactNode }) {
    return (
        <Stack alignItems="center" sx={style}>
            {children}
        </Stack>
    )
}
import './App.css';
import FaceRecognitionComponents from './components';

function App() {    
    return (
        <div
            style={{
                backgroundColor: "lightgrey",
                height: "100vh",
                width: "100vw",
            }}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                }}
            >
                <FaceRecognitionComponents />
            </div>
        </div>
    );
}

export default App;

import React from 'react';
import Box from '@mui/material/Box';
import Content from './Content';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function ContentSuccess() {
    return (
        <Content>
            <h4 style={{ textAlign: "center" }}>
                Certificado de Nada Consta do colaborador verificado com sucesso.
            </h4>
            <Box p={2}>
                <CheckCircleIcon color='success' sx={{ fontSize: '100px' }}/>
            </Box>
        </Content>
    )
}
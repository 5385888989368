import React from 'react';
import Box from '@mui/material/Box';
import Content from './Content';
import ErrorIcon from '@mui/icons-material/Error';

export default function ContentFail() {
    return (
        <Content>
            <h4 style={{ textAlign: "center" }}>
                Nada Consta inválido            
            </h4>
            <Box p={2}>
                <ErrorIcon color='error' sx={{ fontSize: '100px' }}/>
            </Box>
        </Content>
    )
}
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import TableHeader from './TableHeader';
import { RowDocumentNothinContains } from '../../../interfaces/RowDocumentNothinContains';
import CustomTypography from '../../../ui-kit/CustomTypography';

export default function DocumentsTable({ rows }: { rows: RowDocumentNothinContains[] }) {
    return (
        <TableContainer component={Paper} sx={{ marginTop: 3, backgroundColor:'transparent' }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHeader />
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                <CustomTypography>
                                    {row.name}
                                </CustomTypography>
                            </TableCell>
                            <TableCell align="right">
                                <CustomTypography>
                                    {row.expiration}
                                </CustomTypography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    ); 
}
import React from "react";
import { Stack } from "@mui/material";
import AvatarUserDetatils from "./AvatarUserDetatils";
import UserName from "./UserName";
import UserLocal from "./UserLocal";
import UserFunction from "./UserFunction";

type Props = {
    name: string;
    local: string;
    funcao: string;
}

export default function UserDetails({ name, local, funcao }: Props) {
    return (
        <Stack direction="row" alignItems="center" width="100%">
            {/* <AvatarUserDetatils /> */}
            <Stack spacing={2} paddingLeft={3}>
                <UserName name={name} />
                <UserLocal local={local} />
                <UserFunction funcao={funcao} />
            </Stack>
        </Stack>
    );
}
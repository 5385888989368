import { Paper, Stack } from "@mui/material";
import UserDetails from "./UserDetails";
import DocumentsTable from "./DocumentsTable";
import FooterMessage from "./FooterMessage";
import { QrCodeContent } from "../../interfaces/QrCodeContent.interface";

type Props = {
    qrCodeContent: QrCodeContent;
}

export default function NothingContainedPaper({ qrCodeContent }: Props) {
    return (
        <Stack 
            sx={{  
                height: "100vh",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Paper 
                elevation={3} 
                style={{
                    backgroundImage: `url(https://logoscustomers.s3.amazonaws.com/marcaDagua.png)`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    width: '100vw',
                }}
            >
                <Stack 
                    alignItems="center" 
                    paddingX={3} 
                    pb={3}
                >
                    <h1>Nada consta</h1>
                    <UserDetails 
                        name={qrCodeContent.employee} 
                        local={qrCodeContent.local} 
                        funcao={qrCodeContent.funcao} 
                    />
                    <DocumentsTable rows={qrCodeContent.documetsEmployee} />
                    <FooterMessage emissionDate={qrCodeContent.emissionDate} />
                </Stack>
            </Paper>
        </Stack>
    );
}
import React from 'react';
import Modal from '@mui/material/Modal';
import ContentLoading from './ContentLoading';
import ContentSuccess from './ContentSuccess';
import ContentFail from './ContentFail';

type Props = {
    open: boolean; 
    isSuccess: boolean;
    isLoading: boolean;
}

export default function ModalSuuccessRead({ open, isSuccess, isLoading }: Props) {
    return (
        <Modal open={open} >
            <>
            {
                isLoading ?
                <ContentLoading /> :
                isSuccess ?
                <ContentSuccess /> :
                <ContentFail />
            }
            </>
        </Modal>
    )
}
import { CSSProperties, ReactNode } from "react";

type Props = {
    children: ReactNode;
    isBold?: boolean;
    style?: CSSProperties;
    center?: boolean;
}

export default function CustomTypography({ 
    style, 
    children, 
    isBold = false, 
    center = false,
}: Props) {
    return <span
        style={{
            fontSize: "14px",
            fontWeight: isBold ? 600 : 400,
            textAlign: center ? "center" : "start",
            ...style
        }}
    >
        {children}
    </span>
}
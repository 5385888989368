import AWS from 'aws-sdk';
import {Buffer} from 'buffer';

/**
 * Uploads uma imagem para um S3 bucket.
 * @param imageSrc - The base64 encoded image string.
 * @returns A url da imagem upada
 */
export default async function uploadImage(imageSrc: string): Promise<string> {
    const s3 = new AWS.S3({
        accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID_AWS,
        secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY_AWS,
        region: process.env.REACT_APP_REGION_AWS
    });

    const base64Data = Buffer.from(imageSrc.replace(/^data:image\/\w+;base64,/, ""), 'base64');
    const contentType = imageSrc.substring(imageSrc.indexOf(":") + 1, imageSrc.indexOf(";"));
    const imageName = `${Date.now()}.jpg`

    const params = {
        Bucket: "oceanpact-rekognition",
        Key: imageName,
        Body: base64Data,
        ContentEncoding: 'base64',
        ContentType: contentType,
    };

    try {
        const data = await s3.upload(params).promise();
        if (data.Location.length) {
            return imageName;
        } else {
            throw new Error("Não foi possível subir a imagem");
        }
    } catch (error) {
        console.error('Error uploading image to S3:', error);
        throw error;
    }
}